var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-content"},[_c('v-container',{staticClass:"festgeld-nibc-form"},[_c('v-row',[_c('v-col',[_c('h2',{staticClass:"form-headline-content"},[_vm._v("NIBC-Auswertung: Festgeld")])]),_c('v-col',[(_vm.dataTable.festgeld.rows.length)?_c('DownloadFile',{attrs:{"location":"festgeld","param":_vm.formParamFestgeld}}):_vm._e()],1)],1),_c('v-form',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"lg":"3","sm":"5"}},[_c('v-card-text',{staticClass:"pl-0 font"},[_vm._v("Anlagebetrag ")]),_c('v-card-text',[_vm._v("10.000€")])],1),_c('v-col',{attrs:{"lg":"3","sm":"5"}},[_c('v-card-text',{staticClass:"pl-0 font"},[_vm._v(" Anlagedauer ")]),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"color":_vm.color,"item-color":"selected","attach":true,"background-color":"white","item-text":"text","item-value":"value","solo":"","dense":"","items":[
                  { text: '3 Monate', value: 3 },
                  { text: '6 Monate', value: 6 },
                  { text: '9 Monate', value: 9 },
                  { text: '12 Monate', value: 12 },
                  { text: '18 Monate', value: 18 },
                  { text: '2 Jahre', value: 24 },
                  { text: '3 Jahre', value: 36 },
                  { text: '4 Jahre', value: 48 },
                  { text: '5 Jahre', value: 60 },
                  { text: '6 Jahre', value: 72 },
                  { text: '7 Jahre', value: 84 },
                  { text: '8 Jahre', value: 96 },
                  { text: '9 Jahre', value: 108 },
                  { text: '10 Jahre', value: 120 } ]},on:{"change":_vm.handleChangeValue},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.text))])]}},{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.text))])]}}]),model:{value:(_vm.anlagedauer),callback:function ($$v) {_vm.anlagedauer=_vm._n($$v)},expression:"anlagedauer"}})],1)],1)],1),_c('v-col',{attrs:{"lg":"3","sm":"5"}},[_c('v-card-text',{staticClass:"pl-0 font"},[_vm._v(" Datum ")]),_c('spezialabonennten-date-picker',{attrs:{"dates":_vm.data.datePicker.dates,"originalArr":_vm.data.datePicker.originalArr,"storePath":"formParamFestgeld.zeitraum","storeNamespace":"specialSubscribers"},on:{"changed":_vm.handleChangeDate}})],1)],1),_c('v-row',{staticClass:"align-center justify-center"},[_c('v-spacer'),_c('v-col',{attrs:{"sm":"5","lg":"4"}},[_c('v-btn',{staticClass:"pl-0 action-btn",attrs:{"color":_vm.color,"plain":""},on:{"click":_vm.resetForm}},[_vm._v("zurücksetzen")])],1),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }