<template>
  <div>
    <Loader v-if="loading" />
    <div class="form" :style="defaultStyle">
      <SpezialabonnentenFestgeldForm />
    </div>
    <div class="box__error">
      <v-alert
        v-if="error.festgeld"
        border="right"
        colored-border
        type="error"
        elevation="1"
        >Nicht gefunden</v-alert
      >
    </div>
    <v-container>
      <SpezialabonnentenTable
        anbieter
        zinssatz
        :rows="dataTable.festgeld.rows"
        v-if="dataTable.festgeld.rows.length"
        :headers="dataTable.festgeld.headers"
      />
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import SpezialabonnentenTable from '../SpezialabonnentenTable/SpezialabonnentenTable';
import SpezialabonnentenFestgeldForm from './SpezialabonnentenFestgeldForm';
import Loader from '../../common/Loader/Loader';

export default {
  name: 'SpezialabonnentenFestgeldIndex',
  components: {
    SpezialabonnentenTable,
    SpezialabonnentenFestgeldForm,
    Loader,
  },
  computed: {
    ...mapGetters({
      dataTable: 'specialSubscribers/getDataTable',
      error: 'specialSubscribers/getError',
      loading: 'specialSubscribers/getLoading',
    }),
    defaultStyle() {
      const height = window.innerHeight - 335;
      return (
        !this.dataTable.festgeld.rows.length && {
          'min-height': `${height}px`,
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
