<template>
  <div>
    <SpezialabonnentenFestgeldIndex />
  </div>
</template>

<script>
import SpezialabonnentenFestgeldIndex from '../../../wettbewerbsvergleich/src/components/Spezialabonnenten/SpezialabonnentenFestgeld/SpezialabonnentenFestgeldIndex';
export default {
  name: 'SpezialabonnentenFestgeld',
  components: { SpezialabonnentenFestgeldIndex },
};
</script>

<style lang="css" scoped></style>
