<template>
  <div class="form-content">
    <v-container class="festgeld-nibc-form">
      <v-row>
        <v-col>
          <h2 class="form-headline-content">NIBC-Auswertung: Festgeld</h2>
        </v-col>
        <v-col>
          <DownloadFile
            location="festgeld"
            :param="formParamFestgeld"
            v-if="dataTable.festgeld.rows.length"
          />
        </v-col>
      </v-row>
      <v-form>
        <v-row class="justify-center">
          <v-col lg="3" sm="5">
            <v-card-text class="pl-0 font">Anlagebetrag </v-card-text>
            <v-card-text>10.000€</v-card-text>
          </v-col>
          <v-col lg="3" sm="5">
            <v-card-text class="pl-0 font"> Anlagedauer </v-card-text>
            <v-row>
              <v-col>
                <v-select
                  :color="color"
                  item-color="selected"
                  :attach="true"
                  background-color="white"
                  v-model.number="anlagedauer"
                  @change="handleChangeValue"
                  item-text="text"
                  item-value="value"
                  solo
                  dense
                  :items="[
                    { text: '3 Monate', value: 3 },
                    { text: '6 Monate', value: 6 },
                    { text: '9 Monate', value: 9 },
                    { text: '12 Monate', value: 12 },
                    { text: '18 Monate', value: 18 },
                    { text: '2 Jahre', value: 24 },
                    { text: '3 Jahre', value: 36 },
                    { text: '4 Jahre', value: 48 },
                    { text: '5 Jahre', value: 60 },
                    { text: '6 Jahre', value: 72 },
                    { text: '7 Jahre', value: 84 },
                    { text: '8 Jahre', value: 96 },
                    { text: '9 Jahre', value: 108 },
                    { text: '10 Jahre', value: 120 },
                  ]"
                >
                  <template #selection="{ item }">
                    <span>{{ item.text }}</span>
                  </template>
                  <template v-slot:item="{ item }"
                    ><span>{{ item.text }}</span></template
                  >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="3" sm="5">
            <v-card-text class="pl-0 font"> Datum </v-card-text>
            <spezialabonennten-date-picker
              @changed="handleChangeDate"
              :dates="data.datePicker.dates"
              :originalArr="data.datePicker.originalArr"
              storePath="formParamFestgeld.zeitraum"
              storeNamespace="specialSubscribers"
            />
          </v-col>
        </v-row>
        <v-row class="align-center justify-center">
          <v-spacer></v-spacer>
          <v-col sm="5" lg="4">
            <v-btn
              class="pl-0 action-btn"
              :color="color"
              plain
              @click="resetForm"
              >zurücksetzen</v-btn
            >
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import SpezialabonenntenDatePicker from '../SpezialabonnentenDatePicker/SpezialabonnentenDatePicker';
import DownloadFile from '../DownloadFile/DownloadFile';

import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'SpezialabonnentenFestgeldForm',
  components: { SpezialabonenntenDatePicker, DownloadFile },
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
  computed: {
    ...mapGetters({
      data: 'specialSubscribers/getFormParamFestgeld',
      isAuthorized: 'auth/isAuthorized',
      dataTable: 'specialSubscribers/getDataTable',
      formParamFestgeld: 'specialSubscribers/getFormParamFestgeld',
    }),
    ...mapFields('specialSubscribers', ['formParamFestgeld.anlagedauer']),
    ...mapFields('specialSubscribers', ['formParamFestgeld.zeitraum']),
  },
  methods: {
    ...mapMutations({
      resetFormFestgeld: 'specialSubscribers/resetFormFestgeld',
      setBerechnung: 'specialSubscribers/setBerechnung',
      setDataForm: 'specialSubscribers/setDataForm',
      error: 'specialSubscribers/error',
      loading: 'specialSubscribers/loading',
      resetTable: 'specialSubscribers/resetTable',
    }),
    ...mapActions({
      sendForm: 'specialSubscribers/sendForm',
      fetchDataForForm: 'specialSubscribers/fetchDataForForm',
    }),
    async handleChangeValue() {
      await this.resetTable('festgeld');
      return this.fetchData();
    },
    async handleChangeDate(val) {
      const payload = Object.assign(val, { productType: 'festgeld' });
      await this.resetTable('festgeld');
      await this.setBerechnung(payload);
      return this.fetchData();
    },
    async fetchData() {
      await this.fetchDataForForm(this.data)
        .then((res) => {
          const data = Object.assign(res.data, { productType: 'festgeld' });
          this.setDataForm(data);
          this.error({ festgeld: false });
        })
        .catch((e) => {
          if (e.response.status !== 401) {
            this.loading(false);
            this.error({ festgeld: true });
            console.log(e);
          }
        });
      await this.sendForm(this.data);
      this.loading(false);
    },
    async resetForm() {
      await this.resetFormFestgeld();
      await this.resetTable('festgeld');
      await this.fetchData();
    },
  },
  watch: {
    isAuthorized(val) {
      if (val && !Object.values(this.dataTable.festgeld).flat().length) {
        this.fetchData();
      }
    },
  },
  created() {
    if (
      this.isAuthorized &&
      !Object.values(this.dataTable.festgeld).flat().length
    ) {
      this.fetchData();
    }
  },
};
</script>

<style lang="scss" scoped>
.festgeld-nibc-form {
  padding-top: 25px;
  & > div > div > h2 {
    padding-bottom: 30px !important;
  }
}
.my-primary {
  color: $white;
}
</style>
